import * as React from 'react'
import { motion } from 'framer-motion'
import { Link } from '@reach/router'
import arrow from '../../../images/menu-arrow.svg'
import styled from 'styled-components'
import punchIniOSIcon from '../../../images/punchin-ios-icon-menu.png'
import somethingIcon from '../../../images/something-icon-menu.png'
import tjingIcon from '../../../images/tjing-icon-menu.png'
import ouffIcon from '../../../images/ouff-icon.png'

const images = { punchIniOSIcon, somethingIcon, tjingIcon, ouffIcon }

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const dropdownItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const dropdownWrapperVariants = {
  open: {
    display: 'flex',
    height: 'auto',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    display: 'none',
    height: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: 'afterChildren',
    },
  },
}

const MenuItemWrapper = styled(motion.li)`
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  font-family: Gilroy-Semibold, sans-serif;
  font-size: 30px;
  padding: 10px 0;

  > * {
    color: #323232;
    padding: 10px 0;
    :hover {
      color: #323232;
      text-decoration: none;
    }
  }
`

const DropdownWrapper = styled(motion.ul)`
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: start;

  li:nth-child(1) {
    margin-top: 12px;
  }

  a {
    color: #323232;
  }
`

const DropdownItem = styled(motion.li)`
  font-size: 25px;
  padding: 14px 0;
  list-style: none;

  img {
    width: 44px;
    height: 44px;
    margin-right: 8px;
  }
  a {
    text-decoration: none;
  }
`

const MenuItemDropdownWrapper = styled('button')`
  cursor: pointer;
  border: 0;
  background: none;
  color: #323232;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  :focus {
    background: #f1f1f1;
    outline: none;
  }

  img {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    transition: transform 0.3s;
    ${({ isDropdownOpen }) => isDropdownOpen && 'transform: rotate(-180deg);'};
  }
`

const DropdownItemImage = styled('img')`
  width: 30px;
`

const ConditinalWrapperLink = ({ linkTo, toggleOpen, children }) => {
  const { page, href } = linkTo

  if (page) {
    return (
      <Link onClick={toggleOpen} to={page}>
        {children}
      </Link>
    )
  }
  if (href) {
    return (
      <a onClick={toggleOpen} href={href}>
        {children}
      </a>
    )
  }
}

export const MenuItem = ({
  name,
  dropdownItems,
  linkTo,
  toggleOpen,
  menuItem,
  expanded,
  setExpanded,
}) => {
  const isDropdownOpen = menuItem === expanded

  const toggleDropdown = () => {
    setExpanded(isDropdownOpen ? false : menuItem)
  }

  const handleMobileMenuItemClick = () => {
    toggleDropdown()
    toggleOpen()
  }

  return (
    <MenuItemWrapper variants={variants} whileHover={{ scale: 1.05 }}>
      {dropdownItems ? (
        <>
          <MenuItemDropdownWrapper
            onClick={toggleDropdown}
            isDropdownOpen={isDropdownOpen}
          >
            {name}
            <img src={arrow} alt="Dropdown indicator" />
          </MenuItemDropdownWrapper>
          <DropdownWrapper
            initial={false}
            animate={isDropdownOpen && expanded ? 'open' : 'closed'}
            variants={dropdownWrapperVariants}
          >
            {dropdownItems.map(dropdownItem => (
              <DropdownItem
                variants={dropdownItemVariants}
                key={dropdownItem.iconName}
              >
                <ConditinalWrapperLink
                  toggleOpen={handleMobileMenuItemClick}
                  linkTo={dropdownItem.linkTo}
                >
                  {dropdownItem.iconName && (
                    <DropdownItemImage
                      src={images[`${dropdownItem.iconName}Icon`]}
                      alt="App logo image"
                    />
                  )}
                  {dropdownItem.name}
                </ConditinalWrapperLink>
              </DropdownItem>
            ))}
          </DropdownWrapper>
        </>
      ) : (
        <ConditinalWrapperLink
          toggleOpen={handleMobileMenuItemClick}
          linkTo={linkTo}
        >
          {name}
        </ConditinalWrapperLink>
      )}
    </MenuItemWrapper>
  )
}
