import * as React from 'react'
import menuItems from '../menuItems'
import MenuItem from '../desktopMenu/MenuItem'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const Navigation = styled('ul')`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 38px;
  right: 40px;
`

const DesktopMenu = ({ clickedLogo }) => {
  console.log('desktopMenu')
  const [expanded, setExpanded] = useState({})

  useEffect(
    () => {
      if (expanded) {
        setExpanded({})
      }
    },
    [clickedLogo]
  )

  return (
    <nav>
      <Navigation>
        {menuItems.map(menuItem => (
          <MenuItem
            key={menuItem.name}
            menuItem={menuItem}
            expanded={expanded}
            setExpanded={setExpanded}
            {...menuItem}
          />
        ))}
      </Navigation>
    </nav>
  )
}

export default DesktopMenu
