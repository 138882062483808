const menuItems = [
  {
    name: 'Apps',
    dropdownItems: [
      {
        name: 'Something',
        iconName: 'something',
        linkTo: {
          page: '/something',
        },
      },
      {
        name: 'Punch In',
        iconName: 'punchIniOS',
        linkTo: {
          page: '/punchin/ios',
        },
      },
      {
        name: 'Ouff',
        iconName: 'ouff',
        linkTo: {
          page: '/ouff',
        },
      },
      {
        name: 'Tjing',
        iconName: 'tjing',
        linkTo: {
          href: 'https://tjing.se/',
        },
      },
    ],
  },
  {
    name: 'Company',
    linkTo: {
      page: '/company',
    },
  },
  {
    name: 'Blog',
    linkTo: {
      page: '/blog',
    },
  },
]

export default menuItems
