// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ouff-js": () => import("./../../../src/pages/ouff.js" /* webpackChunkName: "component---src-pages-ouff-js" */),
  "component---src-pages-punchin-help-js": () => import("./../../../src/pages/punchin/help.js" /* webpackChunkName: "component---src-pages-punchin-help-js" */),
  "component---src-pages-punchin-ios-js": () => import("./../../../src/pages/punchin/ios.js" /* webpackChunkName: "component---src-pages-punchin-ios-js" */),
  "component---src-pages-punchin-js": () => import("./../../../src/pages/punchin.js" /* webpackChunkName: "component---src-pages-punchin-js" */),
  "component---src-pages-punchin-mac-js": () => import("./../../../src/pages/punchin/mac.js" /* webpackChunkName: "component---src-pages-punchin-mac-js" */),
  "component---src-pages-something-js": () => import("./../../../src/pages/something.js" /* webpackChunkName: "component---src-pages-something-js" */)
}

